import React, { useCallback, useEffect, useMemo } from "react";
import { Form, Layout, Input, Button, Skeleton, Row, Col, message } from "antd";

import { getFirestore, doc, FirestoreDataConverter } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

import { useFirestoreDocumentData } from "@react-query-firebase/firestore";

import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import PageHeader from "../../../components/PageHeader";
import { useQueryAuthUser } from "../../../context/Auth";

import { CONSOLE_HOST } from "../../../config/const";

import * as TaskDetails from "./Details";

import FormTaskType from "./Form/TaskType";
import FormItemRunner from "./Form/Runner";
import FormTaskConfiguration from "./Form/TaskConfiguration";
import FormItemRepeatInterval from "./Form/RepeatInterval";
import FormItemNotificationChannels from "./Form/NotificationChannels";

const TaskDetailsComponent: {
  [key in TaskType]: (props: TaskDocument) => JSX.Element | null;
} = {
  taskProducts: TaskDetails.Products,
  taskProductPrice: TaskDetails.ProductPrice,
  taskParkingTicket: TaskDetails.ParkingTicket,
};

const postConverter: FirestoreDataConverter<WithIdField<TaskDocument, "id">> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot) {
    const data = snapshot.data() as TaskDocument;

    return {
      ...data,
      id: snapshot.id,
    };
  },
};

const Task = React.memo(() => {
  const { t } = useTranslation(["task", "taskRepeatInterval"]);

  const { pathname } = useLocation();

  const { taskId, userId } = useParams<{ taskId?: string; userId?: string }>();

  const { data: authUser } = useQueryAuthUser();

  const taskUserId = userId ?? authUser?.uid;

  const taskPath = `users/${taskUserId}/tasks/${taskId}`;

  const { data: task, isLoading: loading } = useFirestoreDocumentData(
    [taskPath],
    doc(getFirestore(), taskPath).withConverter(postConverter),
    undefined,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const { mutateAsync: onUpdate, isLoading } = useMutation(
    (values: InputTask) => {
      return httpsCallable(
        getFunctions(),
        "onUpdateTask",
      )({
        ...values,
        id: taskId,
        type: task?.type,
      });
    },
    {
      onError: (error: Error) => {
        message.error(error.message);
      },
      onSuccess: () => {
        message.success("Task updated");
      },
    },
  );

  const [form] = Form.useForm();

  useEffect(() => {
    if (task) {
      form.setFieldsValue(task);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  const onSubmit = useCallback((values) => {
    return onUpdate(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extra = useMemo((): React.ReactNode[] => {
    let nextExtra: React.ReactNode[] = [
      // <Button key="delete" danger type="primary">
      //   Delete
      // </Button>,
    ];

    if (window.location.hostname === "localhost") {
      nextExtra = [
        <a
          key="firestore-storage"
          target="_blank"
          rel="noopener noreferrer"
          href={`${CONSOLE_HOST}/firestore/data/users/${taskUserId}/tasks/${taskId}`}
        >
          <Button type="link">Firestore</Button>
        </a>,
        ...nextExtra,
      ];
    }

    return nextExtra;
  }, [taskUserId, taskId]);

  const DetailsComponent = useMemo(() => {
    if (!task?.type) {
      return null;
    }

    const DetailsComponent = TaskDetailsComponent[task?.type];

    return DetailsComponent;
  }, [task?.type]);

  return (
    <>
      <PageHeader
        title={t("task:title")}
        breadcrumb={{
          routes: userId
            ? [
                {
                  path: `../`,
                  breadcrumbName: t("users:title"),
                },
                {
                  path: `../${userId}`,
                  breadcrumbName: t("user:title"),
                },
                {
                  path: `../${userId}/tasks`,
                  breadcrumbName: t("tasks:title"),
                },
                {
                  breadcrumbName: t("task:title"),
                  path: pathname,
                },
              ]
            : [
                {
                  path: "..",
                  breadcrumbName: t("tasks:title"),
                },
                {
                  breadcrumbName: t("task:title"),
                  path: pathname,
                },
              ],
        }}
        extra={extra}
      />
      <Layout.Content className="site-layout-content">
        <Skeleton loading={loading}>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              name: "",
              url: "",
              type: "taskProducts",
              notificationChannels: {
                email: false,
                slack: false,
                notification: true,
              },
            }}
            requiredMark="optional"
            onFinish={onSubmit}
          >
            <Row gutter={32}>
              <Col xs={24} sm={24} md={12} xxl={8}>
                <Form.Item
                  label={t("task:input.label.name")}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t("task:input.error.name.required"),
                    },
                  ]}
                >
                  <Input placeholder={t("task:input.placeholder.name")} />
                </Form.Item>

                <div className="ant-row ant-form-item">
                  <div className="ant-col ant-form-item-label">
                    <label
                      htmlFor="type"
                      title={t("task:select.option.type.label")}
                    >
                      {t("task:select.option.type.label")}
                    </label>
                  </div>
                  <Input
                    disabled
                    readOnly
                    value={
                      task?.type
                        ? (t(
                            `task:select.option.type.option.${task?.type}.label`,
                          ) as string)
                        : ""
                    }
                  />
                </div>

                <FormTaskConfiguration />

                <FormTaskType />

                <FormItemRepeatInterval />

                <FormItemRunner userId={taskUserId} />

                <FormItemNotificationChannels />
              </Col>
              {!!(task && DetailsComponent) && (
                <Col xs={24} sm={24} md={24} xxl={16}>
                  <DetailsComponent {...task} />
                </Col>
              )}
              <Col span={24}>
                <Form.Item>
                  <Button loading={isLoading} type="primary" htmlType="submit">
                    {t("task:button.update")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Layout.Content>
    </>
  );
});

export default Task;
