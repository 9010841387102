import { Routes, Route } from "react-router-dom";

import UsersRoute from "./Users";
import UsersTaskRoute from "../Tasks/Task";
import UsersTasksRoute from "./UsersTasks";

const UsersRouter = () => {
  return (
    <Routes>
      <Route index element={<UsersRoute />} />
      <Route path=":userId/tasks" element={<UsersTasksRoute />} />
      <Route path=":userId/tasks/:taskId" element={<UsersTaskRoute />} />
    </Routes>
  );
};

export default UsersRouter;
