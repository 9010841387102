import { getFunctions, httpsCallable } from "firebase/functions";

import { useMutation } from "react-query";
import { message, Switch } from "antd";

import { TaskStatus } from "../../../../config/const";

const TaskStatusButton = (props: { task: WithIdField<TaskDocument, "id"> }) => {
  const { task } = props;

  const { mutateAsync: onRequestToggle, isLoading: loading } = useMutation(
    () => {
      return httpsCallable(
        getFunctions(),
        "onToggleTask",
      )({
        id: task.id,
      });
    },
    {
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  if (!task?.status) {
    return null;
  }

  return (
    <Switch
      checked={task.status === TaskStatus.PAUSED}
      disabled={task.status === TaskStatus.IN_PROGRESS}
      onChange={() => onRequestToggle()}
      loading={loading}
    />
  );
};

export default TaskStatusButton;
